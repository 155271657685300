function pluginInfoUpload(options) {
    const { getPluginFun } = window.jplusUtil
    const { src, key } = options
    const srcArray = src && src.split('/')
    const len = srcArray.length

    window.jplus.pluginInfo || (window.jplus.pluginInfo = {})
    window.jplus.pluginInfo[key] = {
        name: srcArray[len - 3],
        version: srcArray[len - 2],
        src,
        // 是否已上报过
        isRecord: false
    }

    getPluginFun('probe') && 
    getPluginFun('probe').jplusPluginLoadedGoldlog && 
    getPluginFun('probe').jplusPluginLoadedGoldlog()
}

export default pluginInfoUpload
