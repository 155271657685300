/*
 * @Author: puxiao.wh
 * @Date: 2019-01-14 10:51:53
 * @LastEditors: puxiao.wh
 * @LastEditTime: 2019-01-18 18:03:54
 * @Description: 
 */
import jsonp from 'browser-jsonp'
import util from './lib/util'

window.jplusUtil = util

function load() {
    jsonp({
        url: '//dcms.1688.com/open/query.json?app=DCMS&dataId=236&resourceId=1441408',
        // url: '//dcms.1688.com/open/query.json?app=DCMS&dataId=236&resourceId=828710',
        data: {},
        success: function (json) {
            const sourceMap = JSON.parse(json.content.data[0].versionContent)
            const jsList = []
            const pluginKey = Object.keys(window.jplus.plugin || {})
            const jplusPluginInfo = {}

            sourceMap.jsList.forEach((item) => {
                jplusPluginInfo[item.key] = item.src
                const indexOf = pluginKey.indexOf(item.key)
                if (item.key === 'probe') {
                    jsList.push({
                        src: item.src,
                        key: item.key
                    })
                } else if (indexOf !== -1) {
                    jsList.push({
                        src: item.src,
                        key: item.key
                    })
                }
            })

            window.jplusPluginInfo = jplusPluginInfo
            window.jplusIsDebuge = util.urlx.parse(location.search)['_jplusDebugModel_'] === '1'
            // 获取远程插件版本
            util.importPlugin(jsList)
        }
    })
}



function onReady(fn) {
    var readyState = document.readyState;
    if (readyState === 'interactive' || readyState === 'complete') {
        fn()
    } else {
        window.addEventListener("DOMContentLoaded", fn);
    }
}


onReady(load);

export default load
