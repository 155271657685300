import urlx from 'urlx'
import pluginInfoUpload from './pluginInfoUpload'

window.jplus || (window.jplus = {})
window.jplus.plugin || (window.jplus.plugin = {})

const JplusUtil = {
    urlx,
    isDebug: urlx.parse(location.search)['_jplusDebugModel_'] === '1',
    // 设置插件方法
    setPluginFun(pluginKey, funName, fun) {
        window.jplusPluginFun || (window.jplusPluginFun = {})
        window.jplusPluginFun[pluginKey] || (window.jplusPluginFun[pluginKey] = {})
        window.jplusPluginFun[pluginKey][funName] = fun
    },
    // 获取插件方法
    getPluginFun(pluginKey) {
        window.jplusPluginFun || (window.jplusPluginFun = {})
        return window.jplusPluginFun[pluginKey]
    },
    // 获取插入插件信息
    getPlugins() {
        if (window.jplus.plugin) {
            return window.jplus.plugin
        }
    },
    // 获取dom属性
    getProps(ele) {
        const attributes = ele.attributes || {}
        const keys = Object.keys(attributes)
        const props = {}
        keys.forEach((index) => {
            props[attributes[index].nodeName] = attributes[index].nodeValue
        })
        return props
    },
    // 加载脚本
    importPlugin(scripts, callback = function () {}) {
        if(!Array.isArray(scripts)) scripts = [scripts];
        const HEAD = document.getElementsByTagName("head").item(0) || document.documentElement;
        const s = new Array();
        let loaded = 0;
        for (let i = 0; i < scripts.length; i++) {
            // 判断脚本是否已加载过
            const pluginInfo = window.jplus && window.jplus.pluginInfo
            const readyPlugin = pluginInfo && pluginInfo[scripts[i].key]
            
            // 没有被加载过，侧进行加载
            if(!readyPlugin) {
                // 上报插件信息
                pluginInfoUpload(scripts[i]);
                s[i] = document.createElement("script");
                s[i].setAttribute("type", "text/javascript");
                s[i].onload = s[i].onreadystatechange = function (event) { //Attach handlers for all browsers
                    if (!/*@cc_on!@*/0 || this.readyState == "loaded" || this.readyState == "complete") {
                        loaded++;
                        this.onload = this.onreadystatechange = null; this.parentNode.removeChild(this);
                        // 全部加载完回调
                        if (loaded == scripts.length && typeof (callback) == "function") callback(scripts);
                    }
                };
                s[i].setAttribute("src", scripts[i].src);
                HEAD.appendChild(s[i]);
            }
        }
    },
    // 加载依赖项
    importRelyPlugin(keys, callback = function () {}) {
        // keys 插件名列表
        const srcs = []
        if (!Array.isArray(keys)) keys = [keys]
        keys.forEach((key) => {
            const src = window.jplusPluginInfo[key]
            srcs.push({
                src,
                key
            })
        })
        JplusUtil.importPlugin(srcs, callback)
    },
    // 设置配置项, isRely判断是否需要进行依赖检测
    setConfig(key, config, isRely) {
        // 配置项设置
        window.jplus.plugin[key] || (window.jplus.plugin[key] = [])
        if (Array.isArray(config)) {
            window.jplus.plugin[key] = window.jplus.plugin[key].concat(config)
        } else {
            window.jplus.plugin[key].push(config)
        }

        if(isRely) {
            // 判断该插件是否已被加载过
            const pluginInfo = window.jplus.pluginInfo
            const readyPlugin = pluginInfo[key]
            // 没有被加载过，则进行加载
            if (!readyPlugin) {
                const srcs = []
                const src = window.jplusPluginInfo[key]
                srcs.push({
                    src,
                    key
                })
                JplusUtil.importPlugin(srcs)
            }
        }
    },
    // 获得配置项
    getConfig(key) {
        window.jplus.plugin[key] || (window.jplus.plugin[key] = [])
        return window.jplus.plugin[key]
    },
    // 图片请求
    $get(url, success) {
        const img = new Image()
        img.style.display = "none"
        img.src = url
        document.body.appendChild(img);
        success && success()
        //删除Dom
        setTimeout(() => {
            document.body.removeChild(img)
        }, 50)
    }
}

export default JplusUtil